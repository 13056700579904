import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import ceSearchParams from './defaultSearchParams';
import processParamsSchedule from './util/processParamsSchedule';

const init = (container, { hardRefresh = false } = {}) => {
  const { searchParams: customSearchParams = {} } = processParamsSchedule(window.ceSearchExperiencesParams || {});

  const finalSearchExperiencesParams = {
    hardRefresh,
    ...ceSearchParams,
    ...window.ceSearchExperiencesParams || {},
    searchParams: {
      ...ceSearchParams.searchParams,
      ...customSearchParams
    }
  };
  const root = ReactDOM.createRoot(document.getElementById(container));
  root.render(
    <React.StrictMode>
      <App
        ceSearchExperiencesParams={finalSearchExperiencesParams}
        container={container}/>
    </React.StrictMode>
  );
};
window.initSearchExperiences = container => init(container, { hardRefresh: true });
init('search-experiences-root');

