const allowedScheduledParams = {
  algoliaPromoTags: { resetValue: [] },
  specialCouponNonFilterCategory: { resetValue: [] }
};

const processParamsSchedule = algoliaConfig => {
  const { searchParams } = algoliaConfig || {};
  const { paramSchedule = {} } = searchParams || {};
  if (!paramSchedule || !Object.keys(paramSchedule || {}).length) {
    return algoliaConfig;
  }

  const updatedParams = { ...searchParams };
  const now = new Date();
  Object.entries(paramSchedule).forEach(([param, schedule]) => {
    const { start, end } = schedule;
    const { resetValue } = allowedScheduledParams[param] || {};
    if (start && end && allowedScheduledParams[param]) {
      const valid = now > new Date(start) && now < new Date(end);
      if (!valid) {
        updatedParams[param] = resetValue;
      }
    }
  });

  return {
    ...algoliaConfig,
    searchParams: updatedParams
  };
};

export default processParamsSchedule;
