const noop = () => {};
const ceSearchParams = {
  onScrollUp: noop,
  onExperiencesSearchParamsChange: noop,
  onHitCountChange: noop,
  hideRefinementsInUrl: [],
  enableDarkThemeTiles: false,
  showCalendarFilterViewOnMobile: false,
  hideMobileSearchComponents: false,
  showFullLengthDatePickerMobile: false,
  showChooseDestinationDropdownMobile: false,
  hideDatePickerPills: false,
  showBrandQuickFilters: false,
  showUKCityQuickFilters: false,
  countryWithinDestinationEnabled: false,
  enableAndOperatorCategoryFilter: false,
  enableAlwaysShowClearFilters: false,
  showInfiniteScrollOnTileListing: false,
  showDynamicTitle: false,
  showNewTextForNoResultFound: false,
  showPromoCodeEligible: false,
  sortFeaturedFiltersByDate: false,
  enableProductsOnTheHorizon: false,
  customFilterToggleText: '',
  showCustomFilterToggle: false,
  hideSpecialOffersToggle: true,
  enableMobileTileVariant2Flag: false,
  enableMobileTileVariant3: false,
  showCategoryQuickFilters: false,
  showFullWidthMobileSortByDrawer: true,
  disableCalendars: false,
  searchBarHeaderText: '',
  overrideEnvTag: '',
  searchBarPlaceholderText: null,
  showCurrentlyViewing: false,
  enableAdTiles: true,
  locale: 'en',
  fullScreenCalendarForMobile: false,
  searchParams: {
    defaultSortOption: '',
    availableCities: [],
    onlyShowSpecialFilterCouponProducts: false,
    specialCouponNonFilterCategory: [],
    algoliaPromoTags: [],
    overrideModalToHighlightColors: false,
    featuredFilters: [],
    customInclusiveCalendarEndDate: '',
    customInclusiveCalendarStartDate: '',
    overrideCalendarStartMonth: '',
    overrideShownMonths: 0,
    blockCalendarChange: false,
    searchState: {
      refinementList: {
        city: [],
        categoryTheme: []
      }
    },
    // Add in refinements here to always add them to default search state
    permanentSearchState: {
      refinementList: {}
    },
    searchUrl: '',
    excludePartnerProducts: false,
    onlyOwnedProducts: false,
    sortIndex: '',
    searchIndex: 'ce_experiences',
    propertyIds: [],
    ignoreHideInWebsiteSearch: false,
    recentBookingsThreshold: 20,
    sellOutThreshold: 0.15,
    tourCurrencies: {},
    excludedPropBookingType: [],
    hidePromotions: true,
    showHierarchical: false,
    autoShowDiscount: false,
    autoShowDiscountLimit: 3,
    autoDiscountPercentLimit: 0.2,
    hideAdsChip: false,
    addCtaButton: false,
    enableRedirectAdbutton: false,
    shouldShowFiltersInHalfWindows: true,
    showPriceWithFeesToggle: false,
    searchExperienceV3IsEnabled: true,
    searchExperienceV2IsEnabled: false,
    hitsPerPage: 12,
    disableScrollUp: false,
    keepCalendarOpen: false,
    showMoreLimit: 6,
    hiddenRefinementLists: [],
    hideRefinements: [],
    mobileQuickFilterCruiseTypes: {},
    allowFilterDatesWithPermalink: false,
    startingFilterState: {
      specialCouponCategories: true,
      availableDates: true,
      city: true,
      categoryTheme: true,
      audience: false,
      duration: false,
      country: false,
      brand: false,
      displayPrice: false,
      'hierarchical.lvl0': true
    }
  }
};

export default ceSearchParams;
